// ** Type Imports
import { NextPageWithAuth } from "src/pages/_app";

const Home: NextPageWithAuth = () => {
  return <>Home Page</>;
};

Home.auth = {
  auth: true,
  acl: {
    subject: "home-page",
    action: "read",
  },
};

export default Home;
